@import '../../../../assets/styles/vars';

.AttachFilesDialog {
  .MuiPaper-root {
    width: 776px;
    max-width: 100vw;

    @media screen and (max-width: $screen-sm) {
      width: 100vw;
    }
  }

  .MuiDialogTitle-root {
    padding: 40px 40px 20px;
    background-color: $secondary-bg-color;
    position: relative;

    @media screen and (max-width: $screen-sm) {
      padding: 30px 30px 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 20px 40px;
    background-color: $primary-bg-color;
    min-height: 150px;

    @media screen and (max-width: $screen-sm) {
      padding: 20px 30px;
    }
  }

  .MuiDialogActions-root {
    padding: 20px 40px 30px;
    background-color: $secondary-bg-color;

    @media screen and (max-width: $screen-sm) {
      padding: 20px 30px 30px;
    }
  }
}