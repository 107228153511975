@import '../../../../assets/styles/vars';

.ConfirmDeletionDialog, .AddFeeDialog, .DisplayHTMLDialog, .CloneJourneyDialog, .ScheduleMeetingDialog, .PaymentDialog, .InvitationSentConfirmationDialog, .DisplayAuditLogDialog {
  .MuiPaper-root {
    width: 660px;
    max-width: 100vw;
    border-radius: 10px;
  }

  .MuiDialogTitle-root {
    padding: 40px 40px 20px;
    background-color: $primary-bg-color;
    position: relative;

    @media screen and (max-width: $screen-sm) {
      padding: 30px 30px 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 20px 40px;
    background-color: $primary-bg-color;

    @media screen and (max-width: $screen-sm) {
      padding: 20px 30px;
    }
  }

  .MuiDialogActions-root {
    padding: 20px 40px 30px;
    background-color: $primary-bg-color;

    @media screen and (max-width: $screen-sm) {
      padding: 20px 30px 30px;
    }
  }
}

.DisplayHTMLDialog {
  .MuiPaper-root {
    width: 960px;
  }
}

.CloneJourneyDialog {
  .MuiPaper-root {
    width: 960px;
  }
}

.PaymentDialog {
  .MuiPaper-root {
    width: 1100px;
  }
}

.InvitationSentConfirmationDialog, .DisplayAuditLogDialog {
  .MuiPaper-root {
    width: 736px;
    border-radius: 10px;
  }
}

/*
.ScheduleMeetingDialog {
  .MuiPaper-root {
    width: 960px;
    max-width: 100vw;
  }
}*/
