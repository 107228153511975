@import '../../../../assets/styles/vars';

.errorLabel {
  @extend %font-caption;
  color: $error-color;
}

.successLabel {
  @extend %font-caption;
  color: $orange-color;
}