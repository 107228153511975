@import '../../../assets/styles/vars';

$journeys-table-width: 1120px;

.pageWrapper {
  @extend %standard-page-wrapper;
}

.editIcon {
  color: #DADADA;

  @media screen and (max-width: $screen-sm) {
    position: relative;
    top: 4px;
  }
}

.title {
  padding: 0;
  //white-space: pre-wrap;
  @extend %font-h1;
  line-height: 48px;
  color: black;
  width: calc(100% - 160px);

  &.mobile {
    @extend %font-h3;
    width: calc(100% - 56px);
  }

  &.editable {
    cursor: pointer;
  }
}

.moreButton {
  width: 128px;

  svg {
    margin-right: 16px;
  }
}

.iconBtn {
  padding: 4px;

  &.noPadding {
    padding: 0 !important;
    height: 24px !important;
    width: 24px !important;
  }

  &.linkColor {
    color: $secondary-color;
    position: relative;
    top: 4px;
  }

  &.linkDisableColor {
    color: $disabled-color;
    position: relative;
    top: 4px;
  }

  &.smaller {
    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  &.ml1 {
    margin-left: 8px;
  }
}

.nameEditor {
  width: 100% !important;
  //padding: 0 !important;
  input {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 64px;
    color: $text-header-color;
    letter-spacing: normal;
    text-transform: none;
    text-decoration-line: none;

    @media screen and (max-width: $screen-sm) {
      font-family: Rubik, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: $text-header-color;
      letter-spacing: normal;
      text-transform: none;
      text-decoration-line: none;
    }
  }
}

.completeMark {
  color: transparent;
  background-color: transparent;
  @extend %not-selectable;

   svg {
     height: 20px;
     width: 20px;
   }

  &.completed {
    background-color: $text-inverse-color;
    color: $orange-color;
  }
}

.milestoneItem {
  @extend %font-body2;
  color: $text-primary-color;
  text-transform: uppercase;
  border-bottom: 1px solid $border-color;
  padding: 8px 24px;
  width: 200px !important;
  @extend %not-selectable;
  cursor: pointer;


  &.selected {
    @extend %font-body2-semi-bold;
    color: $text-header-color;
    text-transform: uppercase;
    border-bottom: 2px solid $dark-border-color;
  }

  &.disabled {
    color: $disabled-color;
    border-bottom: 1px solid $border-color;
    cursor: not-allowed;
  }
}

.cardProgress, .globalProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.globalProgress {
  position: fixed;
  top: 40vh;
  margin-top: 0;
}

.spinnerWrapper {
  min-height: 200px;
  position: relative;
}

.selectWrapper {
  width: 100%;

  &.identityVerificationSelector {
    min-width: 160px;
  }
}

.checkIconSelect {
  height: 20px !important;
  width: 20px !important;
  background-color: $text-inverse-color;
  color: $orange-color;
  position: relative;
  top: 5px;
  left: 4px;
  border-radius: 10px;
}

.pageTitle {
  @extend %font-h1;

  &.mobile {
    @extend %font-h3;
  }
}

.subTitle {
  @extend %font-h3;
  color: $text-secondary-color;

  &.mobile {
    @extend %font-h5;
    color: $text-secondary-color;
  }
}


.tableContainer {
  position: relative;
  min-height: 200px !important;
  width: 100%;
  overflow-x: auto !important;

  tr, td, th {
    border: none !important;
  }

  .tableHead {
    th {
      font-family: Open Sans, sans-serif !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 20px !important;
      text-transform: uppercase !important;
      background-color: $secondary-bg-color !important;
      color: $text-secondary-color !important;

      svg {
        margin-left: 4px;
        position: relative;
        top: 9px;
      }
    }
  }

  .tableBody {
    .tableItem {
      background-color: $secondary-bg-color;

      .name, .username, .date, .mobileNumber, .caption {
        @extend %font-button;
        text-transform: none;
        color: $text-primary-color;

        a {
          color: $text-primary-color !important;
        }
      }

      div.caption {
        @extend %font-caption;
        word-break: break-word;
      }


      .milestone, .verified, .notVerified, .substepLabel, .onHold, .done {
        @extend %font-caption;
        border-radius: 5px;
        background-color: $orange-color;
        color: $text-inverse-color;
        width: 100%;
        text-transform: uppercase;
        padding: 4px 4px;
        max-width: 180px;

        &:not(:last-child) {
          margin-bottom: 1px;
        }
      }

      .onHold {
        background-color: $dark-border-color;
      }

      .verified, .notVerified {
        width: 100px;
        max-width: 100%;
        text-align: center;
      }

      .verified, .done {
        background-color: $tertiary-color;
      }

      .substepLabel {
        background-color: $beige-color;
        color: $text-primary-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: none;
        position: relative;
        top: -1px;
      }

      &.timeWarning {
        background-color: rgba(238, 42, 44, 0.2);
      }
    }
  }
}

.tableContainer.fixedWidth {
  tr, thead, table {
    min-width: $journeys-table-width !important;
  }

  .tableHead {
    min-width: $journeys-table-width;
  }

  .tableBody {
    min-width: $journeys-table-width;
  }
}

.emptyTable {
  min-height: 120px;
  @extend %font-body2-semi-bold;
  color: $text-secondary-color;
  width: 100% !important;
  border-bottom: 1px solid $tertiary-bg-color;
  position: relative;
}

.toolbar {
  background-color: $tertiary-bg-color;
  padding: 16px 6px;
  border-radius: 10px 10px 0 0;
  //margin-bottom: 2px;

  .selectorsWrapper {

    .toolbarSelectContainer {
      @media screen and (max-width: $screen-sm) {
        &:not(:last-child) {
          margin-bottom: 16px !important;
        }
      }
    }

  }

  .link {
    @extend %font-caption;
    color: $secondary-color;
    @extend %not-selectable;
    cursor: pointer;
    line-height: 32px;

    &.disabled {
      color: $disabled-color;
      cursor: not-allowed;
    }
  }
}

.pagination {
  margin-top: 16px !important;
}

.smallButton {
  height: 32px !important;
}

.clickable {
  @extend %not-selectable;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
}

.ddIcon {
  position: absolute !important;
  right: 0;
}

.actionButton {
  width: 120px;
  max-width: 100%;

  svg {
    position: relative;
    top: 1px;
    right: -4px;
  }
}

.deleteMenuItem {
  color: $orange-color !important;
}

.fullWidthOnXS {
  @media screen and (max-width: $screen-xs) {
    width: 100% !important;
  }
}

.mobileNestedItem {
  &.first {
    border-top: 1px solid $border-color;
  }

  &.last {
    border-bottom: 1px solid $border-color;
  }
}

.anchor {
  text-decoration: none;
  color: $text-header-color;
}

.moreMenu {
  z-index: 10;
}

.moreMenuPaper, .moreActionsMenuPaper {
  border-radius: 4px;
  background-color: $primary-bg-color !important;
  z-index: 10;
  min-width: 170px;
  position: relative;
  right: 1px;

  .menuItem {
    @extend %font-button;
    text-transform: uppercase !important;
    color: $text-primary-color;
    margin-bottom: 2px;
  }
}

.moreActionsMenuPaper {
  min-width: 110px;
}

.moreCustomersButton {
  width: 170px;

  svg {
    margin-right: 16px;
  }
}

.moreActionsButton {
  width: 148px;
  margin-left: 8px!important;

  svg {
    margin-right: 16px;
  }
}

.label {
  @extend %font-input-label;
}

.value {
  @extend %font-input-label;
  color: $text-header-color;
}

.switcherWrapper {
  width: 48px !important;
  position: relative;
  top: 4px;
  left: 11px;
}

.switcherLabelWrapper {
  width: calc(100% - 64px) !important;
  margin-bottom: 16px !important;

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 8px !important;
  }

  .label {
    @extend %font-body1;
    color: $text-primary-color;

    &.disabledInput {
      color: $text-secondary-color;
    }
  }

  .caption {
    @extend %font-caption;
    color: $text-secondary-color;
  }
}

.journeyNameTitle {
  @extend %font-button;
  text-transform: none;
  color: $text-header-color;
  @extend %not-selectable;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
}

.cardsWrapper {
  margin-top: 0 !important;
  padding-bottom: 8px !important;
  min-width: $journeys-table-width;
}

.card {
  background-color: $beige-color-05;
  border-radius: 10px;
  padding: 8px;

  .date, .userName, .userName>a, .journeyName, .journeyName>a, .journeyReferral {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
    color: #000000;
  }

  .date {
    font-size: 10px;
    color: $text-secondary-color;
  }

  .userName, .userName>a {
    @extend %not-selectable;
    max-width: 164px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .userName {
    height: 16px;
  }

  .userName>a {
    cursor: pointer;
  }

  .journeyName {
    margin-top: 8px;
  }

  .journeyName, .journeyName>a {
    @extend %not-selectable;
    max-width: 164px;
    font-size: 12px;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .journeyName {
    height: 32px;
  }

  .journeyName>a {
    cursor: pointer;
  }

  .journeyReferral {
    font-size: 12px;
    width: 132px !important;
    height: 32px !important;
  }

  .icon {
    color: $text-primary-color;
    padding: 0 !important;
    height: 14px !important;
    width: 14px !important;
    position: relative !important;
    top: 9px !important;
    left: 4px !important;
  }

  .journeyReferralWrapper {
    margin-top: 8px;
    max-width: 164px;
    height: 32px !important;
  }
}

.showMore {
  @extend %not-selectable;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: $text-primary-color;
  justify-content: center !important;
  text-align: center !important;

  span {
    margin-top: 8px;
    cursor: pointer;
  }
}

.emptyTab {
  @extend %font-body2-semi-bold;
  color: $text-secondary-color;
  width: 100% !important;
  text-align: center;
}

.selectSpecialistWrapper {
  max-width: 100% !important;
  width: 100%;
  margin-top: 16px !important;
}

.teamContainer {
  width: 100% !important;
  margin-top: 32px !important;
}

.errorIcon {
  color: $error-color;
}
.warningIcon {
  color: $orange-color;
}
.placeholderIcon {
  color: transparent;
}

.downloadIconButton {
  height: 32px;
  width: 32px;
  color: $secondary-color;
  text-align: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 0;
    position: relative;
    color: $secondary-color;
  }

  &.small {
    height: 24px;
    width: 24px;
    margin-right: 4px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &.disabled {
    color: $text-secondary-color;

    svg {
      color: $text-secondary-color;
    }
  }
}