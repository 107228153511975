@import "fonts";
@import "vars";
@import "matirial-ui-overrides";

body {
  margin: 0px;
}

.calendly-overlay {
  .calendly-popup {
    max-height: 800px !important;
  }
}