@import '../../../../assets/styles/vars';

.closeButton {
  position: absolute !important;
  top: 13px;
  right: 13px;
  height: 32px;
  width: 32px;

  svg {
    height: 32px;
    width: 32px;
  }
}

.modalTitle {
  @extend %font-h2;
  color: black;
  max-width: calc(100% - 8px);

  &.mobile {
    @extend %font-h3;
  }
}

.progressBarContainer {
  height: 6px;
  width: 100%;
  background-color: $tertiary-bg-color;
  margin-bottom: 32px;

  .progressBar {
    height: 6px;
    background-color: $orange-color;
  }

  &.transparent {
    background-color: transparent;
    .progressBar {
      background-color: transparent;
    }
  }
}

.percentageText {
  @extend %font-h4;
  color: $text-secondary-color;
}
