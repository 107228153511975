@import '../../../assets/styles/vars';
@import './chart-colors';

.pageWrapper {
  @extend %standard-page-wrapper;
}

.cardProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.pageTitle {
  @extend %font-h1;

  &.mobile {
    @extend %font-h3;
  }

  &.bottomSpace {
    margin-bottom: 16px;
  }
}

.tableContainer {
  position: relative;
  min-height: 200px !important;
  width: 100%;

  tr, td, th {
    border: none !important;
  }

  .tableHead {
    th {
      font-family: Open Sans, sans-serif !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 20px !important;
      text-transform: uppercase !important;
      background-color: $secondary-bg-color !important;
      color: $text-secondary-color !important;
    }
  }

  .fixedQuarter {
    max-width: 24% !important;
    min-width: 24% !important;
    width: 24% !important;
  }

  .tableBody {

    .tableItem {
      background-color: $primary-bg-color;

      &:nth-child(2n) {
        background-color: $secondary-bg-color;
      }

      .type, .status, .date, .value {
        @extend %font-button;
        text-transform: none;
        color: $text-header-color;
      }

      .status, .date {
        color: $text-primary-color;
      }

      .status {
        .internal {
          @extend %font-caption;
          border-radius: 5px;
          background-color: $orange-color;
          color: $text-inverse-color;
          //width: 100%;
          text-transform: uppercase;
          text-align: center;
          padding: 4px 8px;
          max-width: 80px;
        }
      }

      .date {
        text-transform: uppercase;
      }

      .journeyName {
        @extend %font-caption;
        color: $text-secondary-color;
      }
    }
  }

  &.noMinHeight {
    min-height: initial !important;
  }

}

.journeyNameTitle {
  @extend %font-button;
  text-transform: none;
  color: $text-header-color;
  @extend %not-selectable;
  cursor: pointer;
  text-decoration: underline;
}

.emptyTable {
  min-height: 120px;
  @extend %font-body2-semi-bold;
  color: $text-secondary-color;
  width: 100% !important;
  border-bottom: 1px solid $tertiary-bg-color;
  position: relative;
}

.smallButton {
  height: 32px !important;
}

.toolbar {
  background-color: $tertiary-bg-color;
  padding: 16px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 2px;

  .selectorsWrapper {

    .toolbarSelectContainer {
      @media screen and (max-width: $screen-sm) {
        &:not(:last-child) {
          margin-bottom: 16px !important;
        }
      }
    }

  }
}

.selectorOption, .defaultOption {
  line-height: 28px !important;
  text-transform: none !important;
}

.defaultOption {
  color: $text-secondary-color !important;
}

.pagination {
  margin-top: 8px !important;
}

.link {
  @extend %font-body1;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
  color: $secondary-color;

  a {
    color: $secondary-color !important;
  }
}

.frameRoot {
  min-width: 100%;
  height: calc(100vh);
  border: none;
}

.error {
  @extend %font-body2;
  color: $error-color;
}

.progressBarContainer {
  height: 6px;
  width: 200px;
  background-color: $tertiary-bg-color;
  margin-bottom: 8px;

  .progressBar {
    height: 6px;
    background-color: $orange-color;
  }
}

.percentageText {
  @extend %font-h4;
  color: $text-secondary-color;
}

.title {
  width: 100%;
  @extend %font-h3;
  color: $text-primary-color;
}

.printArea {
  width: 100%;
}

.value, .htmlValue {
  @extend %font-body2;
  color: $text-primary-color;
}

.label {
  @extend %font-body2;
  color: $text-secondary-color;

  &.centered {
    text-align: center;
  }
}

.blueLabel {
  @extend %font-h4;
  color: $tertiary-color;

  &.centered {
    text-align: center;
  }
}

.sup {
  position: relative;
  bottom: 1ex;
  font-size: 80%;
}

.fullWidthOnXS {
  @media screen and (max-width: $screen-xs) {
    width: 100% !important;
  }

  &.halfWideOnMd {
    @media screen and (max-width: $screen-sm) {
      width: 50% !important;
    }

    @media screen and (max-width: $screen-xs) {
      width: 100% !important;
    }
  }
}

.mainImageWrapper {
  width: 100% !important;
  position: relative;
  height: 448px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mainImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .logo {
    height: 16px;
    width: auto;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .propertyInfoBar {
    @extend %font-body1;
    color: $text-inverse-color;
    //border-right-color: $text-inverse-color;
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 100%;


    .propertyInfoItem {
      padding: 2px 20px;
      line-height: 30px;
      height: 30px;
      border-right: 1px solid $text-inverse-color;

      &.noBorder {
        border-right: 1px solid transparent;
      }


      .imageIcon {
        width: auto;
        height: 25px;
        margin-left: 4px;
        position: relative;

        &.bed {
          top: 5px;
        }

        &.car {
          top: 5px;
        }
      }
    }

  }
}

.mainImageContent {
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100% - 80px);
  transform: translateY(-50%);
  color: $text-inverse-color;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 1;

  .imageAddress {
    @extend %font-h1;
  }

  .imageAddress2 {
    @extend %font-h2;
  }

  .imageDate {
    @extend %font-caption;
  }

  .title {
    @extend %font-h4;
  }

  .title, .value, .label, .imageAddress, .imageDate, .imageAddress2 {
    color: $text-inverse-color;
  }

  @media screen and (max-width: $screen-sm) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: $screen-xs) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.mainContentWrapper {
  padding: 16px 40px 0 40px;

  @media screen and (max-width: $screen-sm) {
    padding: 16px 16px 0 16px;
  }

  @media screen and (max-width: $screen-xs) {
    padding: 16px 8px 0 8px;
  }

  .blockTitle, .blockSubTitle {
    @extend %font-h2;
    color: $text-primary-color;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .blockSubTitle {
    @extend %font-h3;
    color: $text-primary-color;
    margin-top: 0;
    margin-bottom: 0;
  }

  .notDefined {
    @extend %font-body2-semi-bold;
    color: $text-secondary-color;
  }

  .subHeader {
    @extend %font-h5;
    color: $text-header-color;
    line-height: 30px;

    &.bold {
      @extend %font-h3;
    }
  }

  .valueBig {
    @extend %font-h4;
    color: $text-primary-color;
    line-height: 30px;

    &.bold {
      @extend %font-h3;
    }
  }

  .lineHolder {
    width: 100%;
    height: 15px;
    border-bottom: 1px solid $text-secondary-color;
  }

  .label {
    @extend %font-body1;
    color: $text-primary-color;

    &.light {
      color: $text-secondary-color;
    }
  }

  .confidence {
    @extend %font-h4;
      color: $green-color;
    &.bad {
      color: $orange-color;
    }

  }

  .priceContainer {
    margin-bottom: 24px;
  }

  .caption {
    @extend %font-caption;
    color: $text-secondary-color;
  }

  .value {
    @extend %font-body2-semi-bold;

    &.link {
      cursor: pointer;
      text-decoration: underline;
      color: $secondary-color;

      a {
        color: $secondary-color !important;
      }
    }
  }

  .tabItem {
    @extend %font-body2;
    color: $text-primary-color;
    border-bottom: 1px solid $border-color;
    padding: 0 8px 8px;
    width: 160px !important;
    text-align: center;
    @extend %not-selectable;
    cursor: pointer;

    @media screen and (max-width: $screen-sm) {
      width: 144px !important;
    }

    @media screen and (max-width: $screen-xs) {
      width: 112px !important;
    }

    &.selected {
      @extend %font-body2-semi-bold;
      color: $text-header-color;
      border-bottom: 2px solid $dark-border-color;
    }
  }

  .rented, .sold {
    @extend %font-caption;
    border-radius: 5px;
    background-color: $orange-color;
    color: $text-inverse-color;
    width: calc(100% - 8px);
    text-transform: uppercase;
    padding: 2px 4px;
    max-width: 64px !important;
    text-align: center;
  }

  .sold {
    background-color: $tertiary-color;
  }

  .photo {
    overflow: hidden;

    img {
      width: 132px;
      height: auto;
    }
  }

  .iconSmall {
    width: auto;
    height: 14px;
    margin-left: 4px;
    position: relative;

    &.car {
      height: 16px;
      top: 2px;
    }

    &.bath {
      height: 16px;
      top: 1px;
    }
  }
}

.subTitle {
  width: fit-content;
  @extend %font-h4;
  color: $text-header-color;
  margin-top: 32px !important;
  margin-bottom: 16px !important;
}

.price {
  width: fit-content !important;

  .value, .label {
    width: fit-content;
  }
}

.fullWidthChart {
  width: 100% !important;
  height: 40vh;
  max-height: 360px;
}

.fullWidthChartPrint {
  width: 620px !important;
  height: 360px !important;
}

.fullWidthPieChart {
  width: 100%;
  height: 40vh;
  max-height: 280px;
}

.fullWidthPieChartPrint {
  width: 620px !important;
  height: 360px !important;
}

.colorRect {
  height: 12px;
  width: 24px;

  &.transparent {
    background-color: transparent;
    border-color: transparent;
  }

  &.ml {
    margin-left: 16px !important;
  }
}

.houseTitle {
  @extend %font-h4;
  color: $pie-chart-color-0;
}

.unitTitle {
  @extend %font-h4;
  color: $pie-chart-color-1;
}

.legendLabel {
  @extend %font-caption;
  color: $text-primary-color;
}

.schoolWrapper {
  border-radius: 5px;
  border: 1px solid $border-color;
  margin-top: 16px !important;
  min-height: 32px;
  padding: 8px;
}

.htmlValue {
  white-space: pre-wrap;
}

.cardImgWrapper {
  overflow: hidden;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 16px;

  img {
    width: 100%;
    height: auto;
  }
}

.icon {
  position: relative;
  top: 6px;

  &.ml {
    margin-left: 12px;
  }
}

.content_iconsWrapper {
  position: relative;
  top: -6px;
}

.mb0 {
  margin-bottom: 0 !important;
}
.mb1 {
  margin-bottom: 8px !important;
}
.mb2 {
  margin-bottom: 16px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 8px !important;
}
.mt2 {
  margin-top: 16px !important;
}

.actionButton {
  min-width: 160px !important;
  height: 48px;

  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.pie_chart_series_color_0 {
  stroke: $pie-chart-color-0;
  fill: $pie-chart-color-0;
  background-color: $pie-chart-color-0;
  border-color: $pie-chart-color-0;
}

.pie_chart_series_color_1 {
  stroke: $pie-chart-color-1;
  fill: $pie-chart-color-1;
  background-color: $pie-chart-color-1;
  border-color: $pie-chart-color-1;
}

.pie_chart_series_color_2 {
  stroke: $pie-chart-color-2;
  fill: $pie-chart-color-2;
  background-color: $pie-chart-color-2;
  border-color: $pie-chart-color-2;
}

.pie_chart_series_color_3 {
  stroke: $pie-chart-color-3;
  fill: $pie-chart-color-3;
  background-color: $pie-chart-color-3;
  border-color: $pie-chart-color-3;
}

.pie_chart_series_color_4 {
  stroke: $pie-chart-color-4;
  fill: $pie-chart-color-4;
  background-color: $pie-chart-color-4;
  border-color: $pie-chart-color-4;
}

.pie_chart_series_color_5 {
  stroke: $pie-chart-color-5;
  fill: $pie-chart-color-5;
  background-color: $pie-chart-color-5;
  border-color: $pie-chart-color-5;
}

.pie_chart_series_color_6 {
  stroke: $pie-chart-color-6;
  fill: $pie-chart-color-6;
  background-color: $pie-chart-color-6;
  border-color: $pie-chart-color-6;
}

.pie_chart_series_color_7 {
  stroke: $pie-chart-color-7;
  fill: $pie-chart-color-7;
  background-color: $pie-chart-color-7;
  border-color: $pie-chart-color-7;
}

.pie_chart_series_color_8 {
  stroke: $pie-chart-color-8;
  fill: $pie-chart-color-8;
  background-color: $pie-chart-color-8;
  border-color: $pie-chart-color-8;
}

.pie_chart_series_color_9 {
  stroke: $pie-chart-color-9;
  fill: $pie-chart-color-9;
  background-color: $pie-chart-color-9;
  border-color: $pie-chart-color-9;
}

.pie_chart_series_color_10 {
  stroke: $pie-chart-color-10;
  fill: $pie-chart-color-10;
  background-color: $pie-chart-color-10;
  border-color: $pie-chart-color-10;
}

.notToPrint, .notToPrintBlock {
  @media print {
    display: none !important;
  }
}

.notToPrintBlock {
  width: 100%;
}

.iconBtn {
  padding: 4px;

  &.noPadding {
    padding: 0 !important;
    height: 24px !important;
  }

  &.linkColor {
    color: $secondary-color;
    position: relative;
    top: 4px;
  }
}

.toPrintOnly {
  display: none !important;

  @media print {
    display: initial !important;
  }
}

.toPrintOnlyBlock {
  height: 0;
  overflow: hidden;

  @media print {
    height: initial;
    overflow: visible;
  }
}

.iconBtn {
  padding: 4px;

  &.noPadding {
    padding: 0 !important;
    height: 24px !important;
  }

  &.linkColor {
    color: $secondary-color;
    position: relative;
    top: 4px;
  }
}

.normalButtonIcon {
  margin-right: 4px;
}

.rentedSpacingForPrint {
  width: 100%;
  height: 0;

  @media print {
    height: 160px;
  }
}

.searchingPropertiesBlock {
  padding: 24px 16px 24px 24px;
  background-color: $secondary-bg-color;
  border-right: 4px;

  .btnWrapper {
    width: 168px;

    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }
  }

  .inputWrapper {
    width: calc(100% - 168px - 24px);

    .searchInput {
      background-color: $primary-bg-color;
    }

    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }
  }
}

.usedReport {
  @extend %font-body1;
  width: 100%;
  text-align: end;

  &.light {
    color: $text-secondary-color;
  }

  .link {
    cursor: pointer;
    text-decoration: underline;

    a {
      color: $text-primary-color !important;
    }
  }
}

.errorCaption {
  @extend %font-caption;
  color: $orange-color;
}

.tableProgress {
  color: $orange-color !important;
  margin-right: 8px;
  position: relative;
  top: 3px;
}