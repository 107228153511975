@import '../../../../assets/styles/vars';

$journeys-table-width: 736px;

.cardProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;

  &.timeLog {
    z-index: 10;
    margin-top: -20px;
    margin-left: -20px;
  }
}

.info {
  @extend %info-font;
  @extend %not-selectable;
  margin-bottom: 16px !important;
}

.editIconsWrapper {
  margin-bottom: 16px !important;
  width: fit-content !important;

  &.up {
    position: relative;
    top: -3px;
  }

  .icon {
    margin-left: 4px !important;
  }
}

.sectionTitle {
  @extend %font-body2-semi-bold;
  color: $text-header-color !important;
  margin-bottom: 16px !important;
}

.selectWrapper {
  max-width: 100% !important;
  width: 100%;

  &.actionStatusSelector {
    min-width: 160px;
  }
}

.placeholder {
  color: $text-secondary-color !important;
}

.toolbarSelectContainer {
  @media screen and (max-width: $screen-sm) {
    &:not(:last-child) {
      margin-bottom: 16px !important;
    }
  }
}

.textArea {
  width: calc(100% - 16px) !important;
  padding: 8px;
  @extend %font-input-value;
  overflow: auto;
  border-radius: 4px !important;
  border: 1px solid $border-color !important;
  outline: none !important;

  &:hover:not(:disabled) {
    border: 1px solid rgba(0,0,0,0.87) !important;
  }

  &:focus:not(:disabled) {
    border: 2px solid rgba(0,0,0,0.87) !important;
    padding: 7px;
  }

}

.inputWithError {
  border-color: $error-color !important;

  div, fieldset {
    border-color: $error-color !important;
  }
}

.link {
  @extend %font-caption;
  color: $secondary-color;
  @extend %not-selectable;
  cursor: pointer;

  &.disabled {
    color: $disabled-color;
    cursor: not-allowed;
  }
}

.addHistoryItemButton {
  min-width: 200px !important;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.logTimeButton {
  min-width: 140px !important;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.subTitle {
  @extend %font-h3;
  color: $text-secondary-color;
  width: 100%;
}

.subTitleMobile {
  @extend %font-h4;
  color: $text-secondary-color;
  width: 100%;
}

.label {
  @extend %font-body2;
  color: $text-secondary-color;
  margin-bottom: 4px!important;

  &.pt1px {
    padding-top: 1px;
  }

  @media screen and (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}

.value {
  @extend %font-body2-semi-bold;
  color: $text-header-color;
  margin-bottom: 4px!important;
  text-align: end;
  overflow-wrap: break-word;

  &.linkToDetails {
    @extend %not-selectable;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;

    a {
      color: $text-header-color;
    }
  }

  @media screen and (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}

.errorIcon {
  color: $error-color;
  position: relative;
  top: 4px;
}

.accordion {
  box-shadow: none !important;
  padding: 0 !important;
  border-top: 1px solid $border-color !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: $primary-bg-color !important;

  &.borderWrapped {
    border-bottom: 1px solid $border-color !important;
  }

  &.noBorder {
    border-top: none !important;
  }

  .summary {
    padding: 0 !important;
    border: none !important;
    width: calc(100% - 32px) !important;
    max-width: calc(100% - 32px);
    background-color: $primary-bg-color !important;

    div {
      margin: 0 !important;
    }
  }

  .details {
    padding: 24px 0 24px 24px !important;
    background-color: $primary-bg-color !important;

    @media screen and (max-width: $screen-xs) {
      padding: 12px 0 18px !important;
    }
  }

  .tableDetails {
    padding: 0 0 0 12px !important;
    background-color: $primary-bg-color !important;
  }

  &.noBg {
    background-color: $primary-bg-color !important;

    .summary {
      background-color: $primary-bg-color !important;
      padding-left: 16px !important;
      padding-right: 8px !important;

      @media screen and (max-width: $screen-xs) {
        padding-left: 8px !important;
      }
    }
  }

}

.accordionHeader {
  @extend %font-body2-semi-bold;
  color: $text-header-color !important;
  margin-right: 8px !important;

  &.empty {
    color: $text-secondary-color !important;
  }

  &.noEmphasis {
    color: $text-secondary-color !important;
  }
}

.historyItemHeader {
  @extend %font-body1;
  color: $text-secondary-color;
  padding-bottom: 5px;
}

.historyItemContent {
  background-color: $tertiary-bg-color;
  border-radius: 0 0 10px 10px;
  padding: 12px 24px 16px 24px;

  &.lighter {
    background-color: $secondary-bg-color;
  }

  .title {
    @extend %font-body1;
    padding-bottom: 8px;
  }

  .text {
    @extend %font-body2-semi-bold;

    &>p {
      @extend %font-body2-semi-bold;
    }
  }

  .type {
    @extend %font-button;
    color: $text-header-color;
  }
}

.smallButton {
  height: 32px !important;

  &.secondary {
    background-color: $primary-bg-color;

    &:hover {
      background-color: $hover-row-color;
    }
  }
}

.iconButton {
  height: 32px;
  width: 32px;
  padding: 0;
  position: relative;
  top: -2px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.editors {
  @extend %font-caption;
  color: $orange-color;
}

.warning {
  background-color: $beige-color;
  @extend %font-body2-semi-bold;
  color: $text-primary-color;
  margin: 0 0 16px !important;
  padding: 16px !important;
  width: calc(100% - 32px);


  .iconBlock {
    width: 40px;
    text-align: center;

    svg {
      background-color: transparent;
      color: $orange-color;
    }
  }

  .textBlock {
    width: calc(100% - 40px);
  }
}

.preWrap {
  white-space: pre-wrap;
}

.fullWidthOnXS {
  @media screen and (max-width: $screen-xs) {
    width: 100% !important;
  }

  &.halfWideOnMd {
    @media screen and (max-width: $screen-sm) {
      width: 50% !important;
    }

    @media screen and (max-width: $screen-xs) {
      width: 100% !important;
    }
  }
}

.checkboxLabel {
  @extend %font-body2;
  color: $text-header-color;
  max-width: calc(100% - 36px);

  &.checked {
    color: $text-secondary-color;
  }
}

.checkbox {
  position: relative;
  top: 1px;

  &:disabled {
    color: $disabled-color;
  }
}

.afterInscriptionIcon {
  margin-left: 8px;
  color: $text-primary-color;

  &.start {
    color: $tertiary-color;
  }

  &.stop {
    color: $orange-color;
  }
}

.switcherWrapper {
  width: 48px !important;
  position: relative;
  left: 11px;
  margin-right: 8px !important;
}

.aggregatedTimeLogTableWrapper {
  .tableContainer {
    position: relative;
    padding-bottom: 4px;
    width: 100%;
    overflow-x: auto !important;

    tr, td, th {
      border: none !important;
    }

    .tableHead {
      th {
        font-family: Open Sans, sans-serif !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 11px !important;
        line-height: 20px !important;
        text-transform: uppercase !important;
        background-color: $secondary-bg-color !important;
        color: $text-secondary-color !important;

        svg {
          margin-left: 4px;
          position: relative;
          top: 9px;
        }
      }
    }

    .tableBody {
      .tableItem {
        background-color: $secondary-bg-color;

        .name, .username, .date, .mobileNumber, .caption {
          @extend %font-button;
          text-transform: none;
          color: $text-primary-color;

          a {
            color: $text-primary-color !important;
          }
        }

        div.caption, .caption {
          @extend %font-caption;
          word-break: break-word;
          a {
            @extend %font-caption;
            text-decoration: underline;
            word-break: break-word;
          }
        }

        .milestone, .verified, .notVerified, .substepLabel, .onHold, .done {
          @extend %font-caption;
          border-radius: 5px;
          background-color: $orange-color;
          color: $text-inverse-color;
          width: 100%;
          text-transform: uppercase;
          padding: 4px 4px;
          max-width: 200px;

          &:not(:last-child) {
            margin-bottom: 1px;
          }
        }

        .onHold {
          background-color: $dark-border-color;
        }

        .verified, .notVerified {
          width: 100px;
          max-width: 100%;
          text-align: center;
        }

        .verified, .done {
          background-color: $tertiary-color;
        }

        .substepLabel {
          background-color: $beige-color;
          color: $text-primary-color;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-wrap: none;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .tableContainer.fixedWidth {
    tr, thead, table {
      min-width: $journeys-table-width !important;
    }

    .tableHead {
      min-width: $journeys-table-width;
    }

    .tableBody {
      min-width: $journeys-table-width;
    }
  }

  .emptyTable {
    min-height: 120px;
    @extend %font-body2-semi-bold;
    color: $text-secondary-color;
    width: 100% !important;
    border-bottom: 1px solid $tertiary-bg-color;
    position: relative;
  }

  .toolbar {
    background-color: $tertiary-bg-color;
    padding: 16px;
    border-radius: 10px 10px 0 0;
    //margin-bottom: 2px;

    .selectorsWrapper {

      .toolbarSelectContainer {
        @media screen and (max-width: $screen-sm) {
          &:not(:last-child) {
            margin-bottom: 16px !important;
          }
        }
      }

    }

    .link {
      @extend %font-caption;
      color: $secondary-color;
      @extend %not-selectable;
      cursor: pointer;
      line-height: 32px;

      &.disabled {
        color: $disabled-color;
        cursor: not-allowed;
      }
    }
  }

  .pagination {
    margin-top: 16px !important;
  }

  .smallButton {
    height: 32px !important;
  }

  .clickable {
    @extend %not-selectable;
    cursor: pointer;
    width: fit-content;
    text-decoration: underline;
  }

  .ddIcon {
    position: absolute !important;
    right: 0;
  }

  .actionButton {
    width: 120px;
    max-width: 100%;

    svg {
      position: relative;
      top: 1px;
      right: -4px;
    }
  }

  .deleteMenuItem {
    color: $orange-color !important;
  }

  .fullWidthOnXS {
    @media screen and (max-width: $screen-xs) {
      width: 100% !important;
    }
  }

  .mobileNestedItem {
    &.first {
      border-top: 1px solid $border-color;
    }

    &.last {
      border-bottom: 1px solid $border-color;
    }
  }

  .anchor {
    text-decoration: none;
    color: $text-header-color;
  }

  .moreMenu {
    z-index: 10;
  }

  .moreMenuPaper {
    border-radius: 4px;
    background-color: $primary-bg-color !important;
    z-index: 10;
    min-width: 170px;
    position: relative;
    right: 1px;

    .menuItem {
      @extend %font-button;
      text-transform: uppercase !important;
      color: $text-primary-color;
      margin-bottom: 2px;
    }
  }

  .moreCustomersButton {
    width: 170px;

    svg {
      margin-right: 16px;
    }
  }

  .label {
    @extend %font-input-label;
  }

  .value {
    @extend %font-input-label;
    color: $text-header-color;
  }

  .switcherWrapper {
    width: 48px !important;
    position: relative;
    top: 4px;
    left: 11px;
  }

  .switcherLabelWrapper {
    width: calc(100% - 64px) !important;
    margin-bottom: 16px !important;

    @media screen and (max-width: $screen-sm) {
      margin-bottom: 8px !important;
    }

    .label {
      @extend %font-body1;
      color: $text-primary-color;

      &.disabledInput {
        color: $text-secondary-color;
      }
    }

    .caption {
      @extend %font-caption;
      color: $text-secondary-color;
    }
  }

  .journeyNameTitle {
    @extend %font-button;
    text-transform: none;
    color: $text-header-color;
    @extend %not-selectable;
    cursor: pointer;
    text-decoration: underline;
  }

  .showMore {
    @extend %not-selectable;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: $text-primary-color;
    justify-content: center !important;
    text-align: center !important;

    span {
      margin-top: 8px;
      cursor: pointer;
    }
  }

  .emptyTab {
    @extend %font-body2-semi-bold;
    color: $text-secondary-color;
    width: 100% !important;
    text-align: center;
  }

  .selectSpecialistWrapper {
    max-width: 100% !important;
    width: 100%;
    margin-top: 16px !important;
  }

  .teamContainer {
    width: 100% !important;
    margin-top: 32px !important;
  }
}

.iconBtn {
  padding: 4px;

  &.noPadding {
    padding: 0 !important;
    height: 24px !important;
  }

  &.linkColor {
    color: $secondary-color;
    position: relative;
    top: 4px;
  }
}

.deleteMenuItem {
  color: $orange-color !important;
}

.notesWrapper {
  padding: 8px 16px;
  border-radius: 10px;
  border: none;
  background-color: $secondary-bg-color;

  &.defaultEditor, &.defaultEditor>div, &.defaultEditor>div>p {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12pt;
    line-height: 16pt;
    color: black;
    letter-spacing: normal;
    text-transform: none;
    text-decoration-line: none;
  }

  &.noTopBorderRadius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.emptyLabel {
  @extend %font-body1;
  color: $dark-border-color;
}

.filterItem {
  @extend %font-body2;
  color: $text-primary-color;
  border-bottom: 1px solid $border-color;
  padding: 0 32px 8px;
  width: fit-content !important;
  @extend %not-selectable;
  cursor: pointer;


  &.selected {
    @extend %font-body2-semi-bold;
    color: $text-header-color;
    border-bottom: 2px solid $dark-border-color;
  }
}