@import '../../../assets/styles/vars';

.Journey-name-editor {
  width: calc(100% - 160px);

  @media screen and (max-width: $screen-sm) {
    width: calc(100% - 56px);
  }
}

.ActionsDashboardTableWrapper, .ActionsTimeLogTableWrapper {
  .MuiTableCell-root {
    padding: 6px 6px !important;

    &.MuiTableCell-sizeSmall {
      padding: 6px 4px !important;
    }
  }
}