@import './assets/styles/vars';


.cardProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;
}

.spinnerWrapper {
  width: 100%;
  height: 90vh;
  position: relative;

  @supports (height: 100svh) {
    min-height: 90svh;
  }
}

