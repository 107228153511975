@import '../../../../assets/styles/vars';

.dropzoneText {
  @extend %font-caption;
  color: $text-secondary-color;
  text-align: center;

  svg {
    position: relative;
    top: 6px;
  }

  &.disabled {
    color: $disabled-color;
  }
}

.dropzoneTextClick {
  @extend %font-body2-semi-bold;
  color: $text-primary-color;
  text-align: center;

  &.disabled {
    color: $disabled-color;
  }
}

.fileLine {
  padding: 4px 20px;

  .fileName {
    @extend %font-body1;
    color: $text-primary-color;
    max-width: calc(100% - 64px);
    word-break: break-all;
    word-wrap: anywhere;
  }

  .removeButton {
    height: 24px;
    width: 24px;
    color: $text-secondary-color;
  }
}

.filesContainer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: $tertiary-bg-color;
}