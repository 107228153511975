@import '../../../assets/styles/vars';
$pie-chart-color-0: $tertiary-color;
$pie-chart-color-1: $orange-color;
$pie-chart-color-2: $text-header-color;
$pie-chart-color-3: rgb(209, 121, 5);
$pie-chart-color-4: rgb(69, 61, 63);
$pie-chart-color-5: rgb(89, 146, 43);
$pie-chart-color-6: rgb(215, 2, 6);
$pie-chart-color-7: rgb(107, 3, 146);
$pie-chart-color-8: rgb(230, 128, 94);
$pie-chart-color-9: rgb(221, 164, 88);
$pie-chart-color-10: rgb(234, 207, 125);