@import '../../../../assets/styles/vars';

.text {
  @extend %font-body2;
  color:$text-primary-color;
  white-space: pre-wrap;

  .blue {
    color:$tertiary-color;
  }
}

.subTitle {
  @extend %font-body2-semi-bold;
  color:$text-header-color;
}

.notesText {
  @extend %font-body2;
  color:$text-primary-color;
  font-style: italic;
}

.checkboxLabel {
  @extend %font-body2;
}

.closeButton {
  position: absolute !important;
  top: 13px;
  right: 13px;
  height: 32px;
  width: 32px;

  svg {
    height: 32px;
    width: 32px;
  }
}

.modalTitle {
  @extend %font-h2;
  color: black;
  max-width: calc(100% - 8px);

  &.mobile {
    @extend %font-h3;
  }
}

.textArea {
  width: calc(100% - 16px) !important;
  padding: 8px;
  @extend %font-input-value;
  overflow: auto;
  border-radius: 4px !important;
  border: 1px solid $border-color !important;
  outline: none !important;

  &:hover:not(:disabled) {
    border: 1px solid rgba(0,0,0,0.87) !important;
  }

  &:focus:not(:disabled) {
    border: 2px solid rgba(0,0,0,0.87) !important;
    padding: 7px;
  }
}

.actionButton {
  min-width: 160px!important;
  height: 48px;

  &.secondary {
    background-color: $primary-bg-color !important;

    &:hover {
      background-color: $hover-row-color !important;
    }
  }

  @media screen and (max-width: $screen-xs) {
    width: 100%;
    margin-top: 16px !important;
  }
}

.cardProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.label {
  @extend %info-font;
  @extend %not-selectable;
  margin-bottom: 16px !important;

  &.noMb {
    margin-bottom: 0 !important;
  }
}

//-----------------------------Option selector - begin---------------------------
.info {
  @extend %font-body2;
  color:$text-secondary-color;
}

.selectWrapper {
  max-width: 100% !important;
  width: 100%;
  margin-top: 2px!important;
  padding-top: 2px!important;
}

.optionType {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-decoration-line: none;
  width: calc(100% - 60px);
  text-transform: none;
  text-align: start;
}
//-----------------------------Option selector - end-----------------------------

.fullWidth {
  width: 100%;
}

.scheduleMeetingDialogWrapper {
  .leftSection {
    width: 184px !important;
  }

  .rightSection {
    width: calc(100% - 184px - 32px) !important;
  }

  .avatar {
    height: 112px;
    width: 112px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 112px;
    @extend %not-selectable;
    background-color: transparent;
    color: $primary-color;
    text-align: center;
  }

  .smallBtn {
    height: 32px !important;
  }

  .caption, .timezoneCaption {
    @extend %font-caption;
    color: $text-secondary-color;
  }

  .info {
    @extend %font-body1;
    color: $text-secondary-color;
    margin-top: 4px;
    text-align: center;
  }

  .label {
    @extend %font-body1;
    color: $text-primary-color;
  }

  .timezoneCaption {
    width: 100%;
    text-align: end;
    margin-top: 4px;

    .value {
      font-weight: 600;
      color: $text-primary-color;
    }

  }

  .icon {
    height: 20px;
    width: 20px;
    position: relative;
    top: 4px;
    background-color: transparent;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .specialistName {
    @extend %font-h3;
  }

  .avatarWrapper, .caption, .specialistName {
    width: fit-content;
    text-align: center;
  }

  .timeSlotsWrapper {
    width: 100%;
    min-width: 300px;
    max-height: calc(60vh - 200px);

    @supports (height: 100svh) {
      max-height: calc(60svh - 200px);
    }
    overflow-y: auto;

    min-height: 320px !important;
  }

  &.mobileContent {
    @extend %font-body2;
    color: $text-header-color;

    .info {
      text-align: end;
    }

    .introductionText {
      width: calc(100% - 62px - 20px);
    }

    .text {
      .emphasis, .amount, .tail, .subTitle {
        @extend %font-body2-semi-bold;
        color: $text-header-color;
      }
    }

    .avatar {
      height: 62px;
      width: 62px;
      border-radius: 50%;
      overflow: hidden;
      line-height: 62px;
      @extend %not-selectable;
      background-color: transparent;
      color: $primary-color;
      text-align: center;
    }

    .specialistName {
      @extend %font-h4;
    }

    .timeSlotsWrapper {
      max-height: calc(70vh - 240px);

      @supports (height: 100svh) {
        max-height: calc(70svh - 240px);
      }
      overflow-y: auto;

      min-height: 200px !important;
    }

    @media screen and (max-width: $screen-xs) {
      .timeSlotsWrapper {
        max-height: calc(90vh - 380px);

        @supports (height: 100svh) {
          max-height: calc(90svh - 380px);
        }

        overflow-y: auto;

        min-height: 200px !important;
      }
    }
  }
}

.dateSelectorWrapper {
  width: 300px;
  max-width: 100%;

  .selectWrapper {
    width: 100%;
    max-width: 100%;
  }
}

.modalCloseButton {
  position: absolute !important;
  top: 24px;
  right: 33px;
  height: 32px;
  width: 32px;

  svg {
    height: 32px;
    width: 32px;
  }

  @media screen and (max-width: $screen-xs) {
    top: 13px;
    right: 13px;
  }
}

.infoContentWrapper {
  .imageWrapper {
    margin-top: 32px;

    svg {
      width: 184px;
      height: auto;
    }
  }

  .textWrapper {
    width: 600px;
    @extend %font-body2;
    color: $text-secondary-color;
    text-align: center;
    max-width: 100%;
    margin-top: 32px;

    .clickable {
      text-decoration: underline;
      cursor: pointer;
    }

    a {
      color: $text-secondary-color !important;
    }

    .emphasis {
      @extend %font-body2-semi-bold;
      color: $text-secondary-color;
    }
  }
}

.auditLogContentWrapper {
  width: 100%;

  .label {
    @extend %font-body2;
    color: $text-primary-color;
    margin-bottom: 8px !important;
  }

  .value {
    @extend %font-body2;
    color: $text-header-color;
    text-align: end;
    word-break: break-word;
  }

  .subTitle {
    @extend %font-body2-semi-bold;
    color: $text-primary-color;
  }

  .caption {
    @extend %font-caption;
    color: $text-primary-color;
  }

  .detailsBlock {
    border: 1px solid $dark-border-color;
    border-radius: 10px;
    padding: 24px 16px;
    background-color: $tertiary-bg-color;
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
  }
}

