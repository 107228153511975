@import '../../../../assets/styles/vars';

.globalContainer {
  max-width: 100vw !important;
  overflow-x: hidden !important;
  background-color: transparent;
  padding-bottom: 16px;

  @media screen and (max-width: $screen-sm) {
    padding-bottom: 0;
  }

  &.noBottomPadding {
    padding-bottom: 0;
    overflow-y: hidden !important;
    height: 100vh;

    @supports (height: 100svh) {
      height: 100svh;
    }
  }
}

.substrate {
  background: $header-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 300px;
  z-index: -10;
  max-height: 50vh;

  @supports (height: 100svh) {
    max-height: 50svh;
  }

  @media screen and (max-width: $screen-sm) {
    display: none !important;
  }
}

.appBar {
  background: $header-color !important;
  transition: none !important;

  &.wizard {
    box-shadow: none !important;
  }
}

.toolBar {
  @extend %standard-header;

  height: $header-height;
  margin: 0 $global-margin;

  &.wizard {
    @extend %wizard-header;
  }

  &.mobile {
    height: $header-height-mobile;
    margin: 0 $global-margin-mobile;

    &.wizard {
      margin: 0 $global-margin-mobile;
    }
  }
}
.root {
  flex-grow: 1;
}

.accountName {
  height: 42px;
  width: 42px;
  background-color: $secondary-color;
  color: $text-inverse-color;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
  border-radius: 21px;
  @extend %not-selectable;
  margin-left: 32px;

  svg {
    height: 36px;
    width: 36px;
    border-radius: 21px;
    position: relative;
    top: 2px;
  }
}

.navControlWrapper {
  width: fit-content !important;

  .navButton {
    padding: 6px 8px;
    min-width: 0;
    margin-right: 32px;

    &.signInWizard {
      margin-right: 0;
    }
  }

  .anchor {
    text-decoration: none;
    color: $text-inverse-color;
  }
}

.mobileMenuToggleIcon {
  height: 36px !important;
  width: 36px !important;
  cursor: pointer;
}

.userMenuPopper {
  top: 4px !important;
}

.userMenuPaper {
  border-radius: 4px;
  background-color: $beige-color !important;
  max-width: 244px;

  .menuItem {
    @extend %font-body2;
    background-color: rgba(255, 255, 255, 0.25);

    &.info {
      color: $text-header-color;
      background-color: transparent;
      cursor: default;

      .emphasis {
        @extend %font-body2-semi-bold;
        color: $text-header-color;
      }
    }

    &.logout {
      background-color: transparent;
    }
  }

  .anchor {
    text-decoration: none;
    color: $text-primary-color;
    width: 100%;
  }
}

.mobileMenuPopper {
  left: 0 !important;
  top: 70px !important;
  margin: 0 !important;
  position: fixed !important;
  transform: translate3d(0, 0, 0) !important;
}

.mobileMenuPaper {
  @extend %font-body2;
  width: calc(100vw + 1px);
  min-height: calc(100vh - 70px);
  background-color: $tertiary-color !important;
  border-radius: 0 !important;

  @supports (height: 100svh) {
    min-height: calc(100svh - 70px);
  }

  .menuItemMobile {
    background-color: transparent;
    color: $text-inverse-color;
    min-height: 48px;
    padding-left: 30px;

    @media screen and (min-width: $screen-xs) {
      padding-left: 24px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

  }

  .anchor {
    text-decoration: none;
    color: $text-inverse-color;
    width: 100%;
  }
}

.specialistLogin {
  @extend %font-button;
  line-height: 32px;
  color: $text-inverse-color;
  margin-left: 12px !important;

  .close {
    text-decoration: underline;
    cursor: pointer;
  }

  .emphasis {
    font-weight: 700;
  }

  @media screen and (max-width: $screen-sm) {
    margin-left: 0 !important;
  }
}

.nestedMenuItem {
  @extend %font-body2;
  background-color: rgba(255, 255, 255, 0.25) !important;
  margin-bottom: 2px;
  padding-left: 8px !important;

  p {
    @extend %font-body2;
  }

  &:hover {
    background-color: rgba(200, 200, 200, 0.2) !important;
  }
}

.extendedMenuItem {
  font-family: Open Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: $text-primary-color !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  text-decoration-line: none !important;
  background-color: rgba(255, 255, 255, 0.25) !important;
  margin-bottom: 2px;

  &:hover {
    background-color: rgba(200, 200, 200, 0.2) !important;
  }
}

.childMenuWrapper {
  background-color: $beige-color;
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 8px 0;
}