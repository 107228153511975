@import '../../../../assets/styles/vars';

.text {
  @extend %font-body2;
  color:$text-secondary-color;
  width: 360px !important;
  max-width: 100% !important;
}

.info {
  @extend %font-h3;
  text-align: center !important;
  width: 100% !important;

  &.mobile {
    @extend %font-h5;
  }
}

.closeButton {
  position: absolute !important;
  top: 13px;
  right: 13px;
  height: 32px;
  width: 32px;

  svg {
    height: 32px;
    width: 32px;
  }
}

.modalTitle {
  @extend %font-h2;
  color: black;
  max-width: 100%;

  &.mobile {
    @extend %font-h3;
  }
}

.actionButton {
  min-width: 160px!important;
  height: 48px;

  &.secondary {
    background-color: $primary-bg-color !important;

    &:hover {
      background-color: $hover-row-color !important;
    }
  }

  @media screen and (max-width: $screen-xs) {
    min-width: 120px!important;
    margin-top: 16px !important;
  }
}

.avatarWrapper {
  width: fit-content;
  text-align: center;

  .avatar {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 160px;
    @extend %not-selectable;
    background-color: transparent;
    color: $primary-color;
    text-align: center;

    &.mobile {
      height: 112px;
      width: 112px;
      line-height: 112px;
    }
  }
}

.contentContainer {
  margin-bottom: 48px;
  margin-top: 48px;
  width: 400px;
  max-width: 100%;

  @media screen and (max-width: $screen-sm) {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

}



