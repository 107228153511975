@import '../../../../assets/styles/vars';

.BlockingLogOutDialog {
  .MuiPaper-root {
    width: 700px !important;
    height: fit-content !important;
    max-height: 96vh;
    max-width: 96vw;
    background-color: $beige-color !important;

    @supports (height: 100svh) {
      max-height: 96svh;
    }
  }

  .MuiDialogContent-root {
    color: $text-primary-color;
    background-color: $beige-color !important;
    border-radius: 10px;
    padding: 0 !important;
    margin: 0 !important;
  }
}