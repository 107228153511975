@import '../../../../assets/styles/vars';

.headerContainer {
  height: 160px;
  border-radius: 10px;
  background-color: $tertiary-bg-color;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $screen-sm) {
    height: 90px;
  }

  @media screen and (max-width: $screen-xs) {
    border-radius: 0;
  }

  .homesBackground {
    position: absolute;
    bottom: 0;
    left: 43%;
    filter: grayscale(100%);
    transform: translateX(-50%);

    @media screen and (max-width: $screen-xs) {
      bottom: -16px;
    }

    @media screen and (max-width: 520px) {
      right: initial;
      left: 0;
      transform: translateX(0);
    }
  }

  .girlCallBackground {
    position: absolute;
    bottom: 0;
    right: calc(240px + 32px - 32px);

    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .titleWrapper {
    height: 100% !important;

    .title {
      @extend %font-h1;
      color: black;
      padding-left: 96px;
      max-width: 61%;
      z-index: 2;

      @media screen and (max-width: 1140px) {
        padding-left: 32px;
      }
    }

    .titleMobile {
      @extend %font-h3;
      color: black;
      padding: 0 32px;
      z-index: 2;

      @media screen and (max-width: $screen-xs) {
        padding: 0 24px;
      }
    }


  }
}