@import '../../../../assets/styles/vars';

.widgetPaper {
  width: 240px;
  border-radius: 4px;
  background-color: $beige-color;
  padding: 8px;
  z-index: 20;
  box-sizing: content-box;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right:8px solid $beige-color;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
  }

  .widgetContent {
    height: calc(104px - 16px);

    .text {
      padding: 8px;
      @extend %font-caption;
      color: $primary-color;
    }

    button {
      height: 36px !important;
    }
  }
}