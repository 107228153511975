@import '../../../../assets/styles/vars';

$right-block-width: 256px;

.pageWrapper {
  @extend %standard-page-wrapper;

  &.noXSPaddings {
    @media screen and (max-width: $screen-xs) {
      padding: 0;
      width: 100vw;
    }
  }
}

.dashboardContainer {
  max-width: 100% !important;
  width: 100% !important;
  overflow: hidden !important;

  .dashboardContent {
    position: relative;
    width: calc(100% - 32px - #{$right-block-width});
    min-height: 330px;

    &.fullWidth {
      width: 100%;
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
    }
  }

  .rightBlock {
    width: $right-block-width;
    height: max-content;
  }
}

.teamBlock, {
  width: 100%;
  border: 1px solid $tertiary-bg-color;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 32px;
  background-color: transparent;

  .blockHeader {
    @extend %font-h5;
    color: $text-secondary-color;
    text-align: center;
    margin: 0 -16px 8px;
  }

  .description {
    @extend %font-caption;
    color: $text-secondary-color;

    div {
      width: 100%;
      text-align: center;
    }
  }

  .scheduleButton {
    height: 32px !important;
  }

  .photosBlock {
    min-height: 72px;
    width: 128px;

    .avatarsWrapper {
      padding: 0;
      text-align: center;
      width: 100%;
      position: relative;
      @extend %not-selectable;

      .avatar {
        border-radius: 36px;
        height: 72px;
        width: 72px;
        overflow: hidden;
        position: absolute;
        line-height: 72px;
        @extend %font-h3;
        @extend %not-selectable;
        color: $text-inverse-color;
        cursor: pointer;
      }
    }
  }
}

.notificationsBlock {
  width: 100%;
  border: 1px solid $tertiary-bg-color;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: transparent;
  padding: 0;

  .blockHeader {
    @extend %font-h5;
    color: $text-secondary-color;
    padding: 32px 32px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
  }

  .emptyBlock {
    @extend %font-caption;
    color: $text-secondary-color;
    padding: 0 32px 32px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .notificationsListBlock {
    @extend %font-body1;
    color: $text-primary-color;
    background-color: $tertiary-bg-color;
    padding: 32px;
    margin-bottom: 2px !important;
    position: relative !important;

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-bottom: 0 !important;
    }

    .summaryElements {
      @extend %font-body2-semi-bold;
      color: $primary-color;
    }

    .closeIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .actionBtn {
      height: 36px;
    }
  }
}

.notificationsMobileRoot {
  overflow-x: hidden;
  width: 100% !important;
  position: relative;

  .notificationsMobileWrapper {
    overflow-x: auto;
    flex-wrap: nowrap;

    .notificationWrapper {
      &:not(:last-child) {
        margin-right: 8px !important;
      }
    }

    .notificationsListBlock {
      @extend %font-body1;
      color: $text-primary-color;
      background-color: $tertiary-bg-color;
      padding: 32px;
      position: relative;
      border-radius: 10px;
      width: calc(100vw - 24px) !important;
      max-width: 414px !important;
      min-width: 296px !important;
      box-sizing: border-box !important;
      height: 100%;

      @media screen and (max-width: $screen-sm) {
        width: calc(100vw - 48px) !important;
        background-color: $tertiary-bg-color;
        max-width: 414px !important;
      }

      @media screen and (max-width: $screen-xs) {
        width: calc(100vw - 32px) !important;
        background-color: $tertiary-bg-color;
        max-width: 414px !important;
      }

      .summaryElements {
        @extend %font-body2-semi-bold;
        color: $primary-color;
      }

      .closeIcon {
        position: absolute;
        top: 8px;
        right: 8px;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;

        &:hover {
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .actionBtn {
        height: 32px;
      }
    }
  }

}

.ddIcon {
  position: absolute !important;
  right: 0;
}

.actionMenu {
  z-index: 10;
}

.actionMenuPaper {
  border-radius: 10px;
  background-color: $primary-bg-color !important;
  z-index: 10;
  min-width: 190px;

  .menuItem {
    @extend %font-button;
    text-transform: uppercase !important;
    color: $text-primary-color;
    margin-bottom: 2px;
  }
}

.cardProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

