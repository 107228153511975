@import '../../../../assets/styles/vars';

.info {
  @extend %font-body2;
  //@extend %not-selectable;
  //margin-bottom: 16px !important;
}

.caption {
  @extend %font-caption;
  color: $text-secondary-color;
}

.captionBlock {
  @extend %font-caption;
  color: $text-secondary-color;

  a {
    color: $text-secondary-color !important;
    text-decoration: underline;
    cursor: pointer;
  }
}

.fullWidthOnMobile {
  width: calc(50% - 16px) !important;

  @media screen and (max-width: $screen-sm) {
    width: 100% !important;
  }
}

.text {
  @extend %font-body2;
  color:$text-header-color;
}

.closeButton {
  position: absolute !important;
  top: 13px;
  right: 13px;
  height: 32px;
  width: 32px;

  svg {
    height: 32px;
    width: 32px;
  }
}

.modalTitle {
  @extend %font-h2;
  color: black;
  max-width: calc(100% - 100px);

  &.mobile {
    @extend %font-h3;
  }
}

.actionButton {
  min-width: 160px!important;
  height: 48px;

  &.secondary {
    background-color: $primary-bg-color !important;

    &:hover {
      background-color: $hover-row-color !important;
    }
  }

  @media screen and (max-width: $screen-xs) {
    width: 100%;
    margin-top: 16px !important;
  }
}

.modeOption {
  @extend %font-body2;
  border-bottom: 1px solid $border-color;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;
  @extend %not-selectable;

  &.active {
    @extend %font-body2-semi-bold;
    color: $primary-color;
    border-bottom: 2px solid $primary-color;
  }
}

.selectWrapper {
  max-width: 100% !important;
  width: 100%;
}

.dropzone {
  border-radius: 10px !important;
  border: none !important;
  min-height: 150px !important;

  svg {
    height: 40px !important;
    width: 40px !important;
  }
}

.optionType {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-decoration-line: none;
  width: calc(100% - 60px);
  text-transform: none;
  text-align: start;
}

.optionDescription {
  @extend %font-caption;
  width: calc(100% - 60px);
  text-align: start;
}

.buttonProgress {
  color: $dark-border-color;
  position: relative;
  left: -8px;
}

.filesContainer {
  border-radius: 10px;
  border: none;
  background-color: $tertiary-bg-color;

  .fileItem {
    padding: 8px 32px 8px;

    &:not(:first-child) {
      margin-top: 4px !important;
    }

    .name {
      @extend %font-body2;
      color: $text-header-color;
      word-break: break-all;
      word-wrap: anywhere;
    }

    .iconButton {
      height: 32px;
      width: 32px;
      color: $text-secondary-color;
      text-align: center;
      justify-content: center;
      position: relative;
      top: 8px !important;

      svg {
        height: 24px;
        width: 24px;
        margin-right: 0;
        position: relative;
      }
    }

  }
}

.emptyBlock {
  padding: 40px;
  border-radius: 10px;
  border: none;
  min-height: 100px;
  background-color: $tertiary-bg-color;
  @extend %font-body2;
  color: $text-header-color;
  text-align: center;

  p {
    margin-top: 10px;
  }
}

.checkboxLabel {
  @extend %font-body2;
}

.textArea {
  width: 100% !important;
  padding: 8px;
  @extend %font-input-value;
  overflow: auto;

  &:last-child {
    width: 10% !important;
  }
}

.docTypeLabel {
  @extend %font-input-label;
  color: $text-primary-color;
}

.downloadIconWrapper {
  width: 28px !important;
  cursor: pointer;

  svg {
    position: relative;
    left: 0;
  }
}

.fileNameWrapper {
  width: calc(100% - 96px) !important;
  overflow-wrap: break-word;
  text-decoration: underline;
  @extend %not-selectable;

  &.mobileSafari {
    width: calc(100% - 64px) !important;
  }

  span {
    cursor: pointer;
  }
}

.cardProgress, .globalProgress {
  color: $orange-color !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.globalProgress {
  position: fixed;
  top: 48vh;
  margin-top: 0;
  z-index: 3000;

  @supports (top: 100svh) {
    top: 48svh;
  }
}
