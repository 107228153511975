@import '../../../../assets/styles/vars';

.actionName {
  @extend %font-caption;
  color: $text-inverse-color;
  cursor: pointer;
  text-decoration-line: underline;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.trackedTime {
  @extend %font-caption;
  color: $text-inverse-color;
}

.notSelectable {
  @extend %not-selectable;
}

.afterTimeStop, .beforeTimeStop {
  margin-left: 8px;
  margin-right: 0;
  height: 12px;
  width: 12px;
  background-color: $orange-color;
  cursor: pointer;
  float: right;
  position: relative;
  top: 3px;
}

.beforeTimeStop {
  margin-left: 0;
  margin-right: 6px;
  float: left;
}

.actionTimerWrapper {
  width: fit-content !important;
  margin-right: 32px !important;
  max-width: 160px;

  text-overflow: ellipsis;
  overflow: hidden;

  &.noMargin {
    margin-right: 0 !important;
  }
}