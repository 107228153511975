@import '../../../../assets/styles/vars';

.note {
  background-color: $beige-color;
  @extend %font-body2-semi-bold;
  color: $text-primary-color;
  margin: 0 !important;
  padding: 16px !important;
  width: 100%;

  .link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    @extend %not-selectable;
  }

  .iconBlock {
    width: 40px;
    color: $text-inverse-color;
    text-align: center;
  }

  .textBlock {
    width: calc(100% - 40px);
  }
}