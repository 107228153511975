@import '../../../../assets/styles/vars';

.errorLabel {
  @extend %font-caption;
  color: $error-color;
  width: 100%
}

.error {
  border-color: $error-color !important;
}