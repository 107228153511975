@import '../../../../assets/styles/vars';

.leftIndicator, .rightIndicator {
  padding-top: 3px;
  text-align: center;
  opacity: 0.4;
  background-color: #EAEAEA;
  height: calc(30px - 3px);
  width: 30px;
  line-height: 30px;
  border-radius: 15px;
  position: absolute;
  bottom: 50%;
  transform: translateY(-50%);
  z-index: 10;

  .material-icons {
    color: black !important;
  }

  &.contrast {
    opacity: 0.6;
    background-color: #FBFBFB;
    z-index: 10;
  }
}

.leftIndicator {
  left: 0;
}

.rightIndicator {
  right: 0;
}