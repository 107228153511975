@import '../../../../assets/styles/vars';

.ActionTimeLogTableInCaseForm {
  .MuiTableCell-root {
    padding: 8px 6px !important;

    &.MuiTableCell-sizeSmall {
      padding: 6px 4px !important;
    }
  }
}