@import 'vars';

.MuiButton-root {
  @extend %standard-button;

  &.MuiButton-text {
    height: initial;
  }

  &.MuiButton-containedPrimary {
    &:hover {
      background-color: $hover-primary-color;
    }
  }

  &.MuiButton-outlinedPrimary {
    color: $primary-color;
    border-color: $primary-color;
    background-color: $primary-bg-color;
    &:hover {
      background: $hover-row-color;
      border-color: $primary-color;
    }
  }
  &.MuiButton-outlinedSecondary {
    color: $primary-color;
    border-color: $dark-border-color;
    background-color: $primary-bg-color;
    &:hover {
      background: $hover-row-color;
      border-color: $dark-border-color;
    }
  }

  &.MuiButton-outlined {
    &:hover {
      background-color: $hover-row-color;
    }
  }

}


.MuiInputBase-root {
  @extend %standard-input;

  .MuiInputBase-input {
    @extend %font-input-value;
  }

  .MuiOutlinedInput-input {
    padding: 2px 16px !important;
  }

  fieldset {
    border-color: $border-color !important;
  }

  &.Mui-focused, &:hover {
    fieldset {
      border-color: $text-header-color !important;
    }
  }

  &.Mui-error {
    fieldset {
      border-color: $error-color !important;
    }
  }
}

.DISABLE_STANDARD_ERROR_BORDER {
  .MuiInputBase-root {
    fieldset {
      border-color: $border-color !important;
    }

    &.Mui-focused, &:hover {
      fieldset {
        border-color: $text-header-color !important;
      }
    }

    &.Mui-error {
      fieldset {
        border-color: $border-color !important;
      }

      &.Mui-focused, &:hover {
        fieldset {
          border-color: $text-header-color !important;
        }
      }
    }
  }
}

.SMALL {
  .MuiInputBase-root {
    @extend %small-input;

    .MuiInputBase-input {
      @extend %small-font-input-value;
    }

    .MuiOutlinedInput-input {
      padding: 2px 8px !important;
    }
  }

  .MuiIconButton-sizeMedium {
    padding: 0 !important;
    height: 24px !important;
  }

  .MuiIconButton-edgeEnd {
    margin-right: 0;
  }
}

.WHITE-BG {
  .MuiInputBase-root {
    background-color: white !important;

    .MuiInputBase-input {
      background-color: white !important;
    }

    .MuiOutlinedInput-input {
      background-color: white !important;
    }
  }

  .MuiIconButton-sizeMedium {
    background-color: white !important;
  }

  .MuiIconButton-edgeEnd {
    background-color: white !important;
  }


}

.EXTRA-SMALL {
  .MuiInputBase-root {
    @extend %extra-small-input;

    .MuiInputBase-input {
      @extend %small-font-input-value;
    }

    .MuiOutlinedInput-input {
      padding: 2px 8px !important;
    }
  }
}

//TODO check this after merging with material-ui-update
.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-formControl {
  padding: 0;
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    @extend %font-caption;
    color: $text-inverse-color;
    background-color: $tooltip-bg-color;
    white-space: pre-line;
    padding: 16px;

    .MuiTooltip-arrow {
      color: $tooltip-bg-color;
    }
  }
}

.material-icons {
  color: $primary-color !important;
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}

.MuiAccordion-root::before {
  background-color: transparent !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.MuiAccordionSummary-content {
  width: fit-content !important;
}

.MuiAccordionSummary-root {
  display: inline-flex;
}

.SnackbarContainer {
  width: 700px !important;
  max-width: 100vw !important;
  @extend %font-body2-semi-bold;

  div[class*="SnackbarItem-contentRoot-"] {
    width: 700px;
    max-width: 100vw;
    @extend %font-body2-semi-bold;
  }

  svg {
    margin-right: 16px;
  }

  &.anchorOriginPositioning {
    top: 35px !important;

    @media screen and (max-width: $screen-sm) {
      top: 75px !important;
    }
  }
  #notistack-snackbar {
    max-width: calc(100% - 70px) !important;
  }
  .SuccessColorToReplace {
    background-color: $orange-color !important;
  }
  .InfoColorToReplace {
    background-color: $beige-color !important;
    color: $text-primary-color !important;

    .SnackbarItem-action {
      svg {
        color: $text-primary-color !important;
      }
    }
  }

  .SnackbarCloseButton {
    color: $primary-bg-color;
    padding: 8px !important;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 0;
    svg {
      margin: 0 !important;
    }
  }

  #client-snackbar {
    padding-right: 40px;
  }

  div[id*="client-snackbar"] {
    padding-right: 40px;
  }
}

.MuiCollapse-container {
  @at-root p#{&} {
    top: 45px;
  }
}
//SnackBars - end


.MuiFormHelperText-root {
  margin: 0 !important;
}

.MuiSwitch-root {
  width: 48px !important;
  height: 24px !important;
  padding: 0 !important;

  .MuiSwitch-switchBase {
    padding: 0 !important;
    top: 2px !important;
    left: 2px !important;
    color: white !important;
    &.colorPrimary {
      color: white !important;
    }

    &.colorSecondary {
      &.Mui-checked {
        color: white;
      }
      &.Mui-disabled {
        color: white;
      }
    }

    &.Mui-disabled {
      color: white !important;
    }

    &.Mui-checked {
      transform: translateX(24px);

    }

    .MuiSwitch-input {
      width: 48px !important;

    }

    .MuiSwitch-thumb {
      height: 20px !important;
      width: 20px !important;
      border-radius: 12px;
    }
  }
}

.MuiSwitch-track {
  border-radius: 24px !important;
  opacity: 1 !important;
  background-color: $dark-border-color !important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: $primary-color !important;
}

.Mui-checked {
  color: white !important;
}

.Mui-disabled {
  .MuiSwitch-track {
    opacity: 0.15 !important;
  }
}

//Select overrides - begin
.MuiInputBase-root {
  .MuiSelect-select {
    @extend %font-button;
    text-align: center;
    line-height: 36px;
    text-transform: uppercase;
    width: 100%;

    div {
      width: 100%;
    }
  }
}


.MuiMenu-paper.MuiPopover-paper {
  ul.MuiList-root.MuiMenu-list {
    li.MuiMenuItem-root, li.MuiMenuItem-root p {
      width: 100%;
      height: 36px;
      vertical-align: center;
      @extend %font-button;
      //text-align: center;
      line-height: 36px;
      text-transform: uppercase;

      &a {
        @extend %font-button;
        color: $primary-color !important;
        background-color: $text-inverse-color !important;
        text-decoration: none !important;

      }

      &.Mui-selected {
        background-color: $primary-color;
        color: $text-inverse-color;
      }

      div {
        width: 100%;
        text-align: center;
      }
    }

    li.MuiMenuItem-root p {
      padding-left: 12px;
    }
  }
}
//Select overrides - end

.MuiCheckbox-root {
  color: #B0B0B0 !important;
  padding: 0 !important;

  &.Mui-checked {
    color: $primary-color !important;
  }

  &.Mui-disabled {
    color: $disabled-color !important;
  }

  &.Mui-disabled.Mui-checked {
    color: $disabled-color !important;
  }
}

.MuiTableCell-root {
  padding: 14px !important;

  &.MuiTableCell-sizeSmall {
    padding: 8px 4px !important;
  }
}

[class*='PrivateSwitchBase-root-'] {
  padding: 0 !important;

  [class*='PrivateSwitchBase-input-'] {
    height: 24px !important;
    width: 24px !important;
  }
}


.MuiRadio-colorSecondary, .MuiRadio-colorPrimary {
  color: $primary-color !important;
  margin-right: 10px !important;
  padding: 0 !important;

  &.Mui-checked {
    color: $primary-color !important;
  }
}

[role='radiogroup'] {
  .MuiFormControlLabel-root {
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
  }
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
  @extend %font-button;
  color: $text-header-color;
  position: relative;
  top: 2px;
}

.Paginator_global {
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    top: 3px;
  }
}

.MULTIPLE_SELECTOR {
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    top: 0;
  }
}

.MuiAutocomplete-hasPopupIcon {
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding-right: 8px;
  }
}

.MENU_WITH_CHECKMARKS {
  .MuiMenuItem-root.Mui-selected {
    background-color: transparent !important;
  }
}

.MuiTelInput-ListItemIcon-flag {
  width: fit-content !important;
}
.MuiTelInput-ListItemText-country {
  width: fit-content !important;
  text-align: left !important;
  min-width: 10px !important;
  span {
    width: fit-content !important;
  }
}
.MuiTelInput-Typography-calling-code {
  width: fit-content !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {

  .MuiSelect-select {
    padding: 2px 25px 2px 8px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position:relative;

    div {
      position:relative;
      top: -2px;
    }
  }

  &.CENTERED_SELECTOR {
    .MuiSelect-select {

      div {
        text-align: center;
        position:relative;
        top: -1px;
      }
    }
  }
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
    padding-right: 26px !important;
  }
}







