@import '../../../../assets/styles/vars';

.label {
  @extend %font-body1;
  line-height: 32px;
  margin-right: 8px;

  @media screen and (max-width: $screen-sm) {
    line-height: 24px;
  }
}

.option {
  @extend %font-h4;
  color: inherit;
  font-weight: normal;
  position: relative;
  left: -8px;
  top: 0;
}