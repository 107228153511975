@import '../../../assets/styles/vars';

.estimatedPriceHistory_chartWrapper, .demographics_chartWrapper, .marketPerformance_chartWrapper {
  margin: 16px 0 !important;
  width: 100%;

  svg.ct-chart-bar, svg.ct-chart-line{
    overflow: visible;
  }
  .ct-label.ct-label.ct-horizontal.ct-end {
    /*position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space:nowrap;*/
    word-break: break-word;
  }
}

.demographics_chartWrapper {
  margin: 0 0 !important;
}

.tertiaryColor_chart {
  stroke: $tertiary-color;
}

.SalesHistoryTableInPropertyReport {
  .MuiTableCell-root {
    padding: 8px 6px !important;

    &.MuiTableCell-sizeSmall {
      padding: 6px 4px !important;
    }
  }
}

.chartist-tooltip {
  background-color: $tertiary-bg-color;
  opacity: 0;

  &::before {
    border-top-color: $tertiary-bg-color;
    opacity: 0;
  }

  .chartist-tooltip-meta {
    @extend %font-body1;
    color: $text-primary-color;
  }

  .chartist-tooltip-value {
    @extend %font-body2-semi-bold;
  }

  &.tooltip-show {
    display: initial;
    opacity: 0.9;

    &::before {
      opacity: 0.9;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 10mm;
}
