@import '../../../../assets/styles/vars';

.pageWrapper {
  @extend %standard-page-wrapper;
  padding-bottom: 0 !important;

  &.noXSPaddings {
    @media screen and (max-width: $screen-xs) {
      padding: 0;
      width: 100vw;
    }
  }
}

.dashboardContainer {
  max-width: 100% !important;
  width: 100% !important;
  overflow: visible !important;

  .wizardPaper {
    @extend %headered-wizard-paper;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -32px;
    min-height: 20vh;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin: 0;

    &.movedBottom {
      top: -24px;
      max-width: 1120px;;
    }

    @supports (height: 100svh) {
      min-height: 20svh;
    }

    @media screen and (max-width: $screen-sm) {
      box-shadow: none;
      top: 0;
      width: 100%;
    }

    &.noPaperPaddings {
      padding: 0;
    }

  }

}

.actionButton {
  min-width: 160px!important;
  height: 48px;

  @media screen and (max-width: $screen-xs) {
    width: 100%;
    margin-top: 16px !important;
  }

  &.noNarrowScreenButtonsFullWidth {
    width: initial;
    margin-top: 0 !important;

    @media screen and (max-width: $screen-xs) {
      min-width: 120px!important;
    }
  }
}

.mainGrid {
  min-height: 21vh;

  @supports (height: 100svh) {
    min-height: 21svh;
  }

  @media screen and (max-width: $screen-sm) {
    min-height: calc(100vh - 185px - 88px);

    @supports (height: 100svh) {
      min-height: calc(100svh - 185px - 88px);
    }
  }

  @media screen and (max-width: $screen-xs) {
    min-height: calc(100vh - 150px - 88px);

    @supports (height: 100svh) {
      min-height: calc(100svh - 150px - 88px);
    }
  }

  flex-wrap: initial !important;
}


.buttonWrapper {
  padding: 24px 96px 40px 96px;
}

.titleSection {
  padding: 45px 96px 8px 96px;
  white-space: pre-wrap;
  @extend %font-h2;
  color: black;

  &.mobile {
    @extend %font-h4;
    padding-top: 4px;
  }
}

.aboveCardTitleSection {
  padding: 0 96px 16px 96px;
  white-space: pre-wrap;
  @extend %font-h2;
  color: black;

  &.mobile {
    @extend %font-h4;
    padding-top: 4px;
  }

  @media screen and (max-width: $screen-sm) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.noHeader {
    padding: 0 0 40px 0;

    &.mobile {
      padding-top: 4px;
    }

    /*@media screen and (max-width: $screen-sm) {
      padding-left: 30px;
      padding-right: 30px;
    }*/
  }
}

.contentSection {
  padding: 24px 96px 12px 96px;
  &.beige {
    background-color: $beige-color-05;
    padding: 24px 72px 12px 72px;
  }

  &.appendix {
    padding-top: 0;
  }

  &.noContentPaddings {
    padding: 0;
  }
}

@media screen and (max-width: $screen-sm) {
  .buttonWrapper {
    padding: 24px 32px 30px 32px;
  }

  .titleSection {
    padding: 16px 32px 8px 32px;
  }

  .contentSection {
    padding: 24px 32px 12px 32px;
    &.beige {
      padding: 24px 32px 12px 32px;
    }
    &.appendix {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .buttonWrapper {
    padding: 24px 24px 30px 24px;
  }

  .titleSection {
    padding: 16px 24px 8px 24px;
  }

  .contentSection {
    padding: 24px 24px 12px 24px;
    &.beige {
      padding: 24px 24px 12px 24px;
    }
    &.appendix {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.progressBarContainer {
  height: 6px;
  width: 100%;
  background-color: $tertiary-bg-color;
  margin-bottom: 32px;

  .progressBar {
    height: 6px;
    background-color: $orange-color;
  }

  &.transparent {
    background-color: transparent;
    .progressBar {
      background-color: transparent;
    }
  }
}

.buttonProgress {
  color: $dark-border-color;
  position: relative;
  left: -8px;
}