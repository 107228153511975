$screen-xs: 599px;
$screen-sm: 959px;
$screen-md: 1279px;
$screen-lg: 1919px;

$primary-color: #1b1f3b;
$secondary-color: #2597E3;
$tertiary-color: #006EB8;
$orange-color: #FF7700;
$green-color: #008000;
$beige-color: #FBEFDD;
$beige-color-05: rgba(251, 239, 221, 0.5);

$error-color: #EE2A2C;

$text-header-color: #232735;
$text-primary-color: #505565;
$text-secondary-color: #8B90A0;
$text-inverse-color: #FFFFFF;

$primary-bg-color: #FFFFFF;
$secondary-bg-color: #FAFAFC;
$tertiary-bg-color: #f0f1f3;
$border-color: #d3d4d8;
$disabled-color: #D3D4D8;
$dark-border-color: #A1A4B1;
$dark-bg-color: #232735;
$tooltip-bg-color: #000000;

$hover-primary-color: #3E465F;
$hover-row-color: #EEEEE1;

$header-color: $tertiary-color;
$hint-color: $text-secondary-color;

$global-margin: 160px;
$global-margin-mobile: 8px;
$global-padding: 24px;
$global-padding-sm: 16px;
$global-padding-xs: 16px;
$header-height: 80px;
$header-height-mobile: 75px;

%font-h1 {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 64px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-h2 {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-h3 {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-subTitle {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;

  @media screen and (max-width: $screen-sm) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

%font-h4 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-h5 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-h6 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $text-header-color;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  text-decoration-line: none;
}

%font-body1 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-body2 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-body3 {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;

  @media screen and (max-width: $screen-sm) {
    font-size: 18px;
  }
}

%font-text-link {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: $primary-color;
  text-decoration-line: underline;
  letter-spacing: normal;
  text-transform: none;
}

%font-body2-semi-bold {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-large-paragraph {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-large-text-link {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: $primary-color;
  text-decoration-line: underline;
  letter-spacing: normal;
  text-transform: none;
}

%font-caption {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-quotation {
  font-family: Open Sans, sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: $text-primary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%font-button {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: $primary-color;
  letter-spacing: 0.5px;
  text-transform: none;
  text-decoration-line: none;
}

%font-input-label {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $text-secondary-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;

  @media screen and (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

%font-input-value {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;

  @media screen and (max-width: $screen-sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

%small-font-input-value {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $text-header-color;
  letter-spacing: normal;
  text-transform: none;
  text-decoration-line: none;
}

%title-font {
  @extend %font-h1;
}

%info-font {
  @extend %font-input-label;
}

%standard-button {
  padding: 8px 16px;
  height: 48px;
}

%standard-input {
  height: 48px;
}

%small-input {
  height: 32px;
  padding: 0 !important;
}

%extra-small-input {
  height: 26px;
  padding: 0 !important;
}

%not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%standard-page-wrapper {
  width: calc(100vw - #{2 * $global-padding});
  padding: $global-padding;
  overflow-y: auto;
  margin-top: $header-height;
  min-height: 50vh;

  max-width: 1120px;
  position: relative;
  left: 50%;
  transform: translateX(calc(-50%));


  @media screen and (max-width: $screen-md) {
    width: calc(100vw - #{2 * $global-padding});
    margin-top: $header-height-mobile;
  }

  @media screen and (max-width: $screen-xs) {
    padding: $global-padding-xs;
    width: calc(100vw - #{2 * $global-padding-xs});
  }

  @supports (height: 100svh) {
    min-height: 50svh;
  }

}

%wizard-container {
  position: relative;
  left: 50%;
  margin: 0 $global-padding;
  transform: translateX(calc(-50% - #{$global-padding}));
  width: calc(100vw - #{2 * $global-padding});
  max-width: 790px;

  @media screen and (max-width: $screen-sm) {
    width: calc(100vw - 192px);
  }
}

%wizard-header {
  @extend %wizard-container;
  transform: translateX(calc(-50% - #{$global-padding}));
  width: calc(100vw - #{2 * $global-padding});
  max-width: calc(790px + 192px);
  margin: 0!important;
  margin-left: $global-padding !important;

  @media screen and (max-width: $screen-sm) {
    transform: translateX(calc(-50%));
    width: calc(100vw - 120px);
    margin: 0!important;
  }

  @media screen and (max-width: $screen-xs) {
    width: calc(100vw - 40px);
  }
}

%standard-header {
  @extend %standard-page-wrapper;
  transform: translateX(calc(-50% - #{$global-padding}));
  width: calc(100vw - #{2 * $global-padding});
  max-width: calc(1120px);
  margin: 0!important;
  margin-left: 27px !important;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: visible;

  @media screen and (max-width: $screen-sm) {
    width: calc(100vw - 50px);
    margin: 0!important;
    margin-left: 26px !important;
  }

  @media screen and (max-width: $screen-xs) {
    width: calc(100vw - 38px);
    margin: 0!important;
    margin-left: 26px !important;
  }
}

%wizard-paper {
  @extend %wizard-container;
  padding: 72px 96px 40px 96px;
  width: calc(100vw - #{2 * $global-padding} - 192px);
  border-radius: 10px !important;
  overflow: hidden !important;

  @media screen and (max-width: $screen-sm) {
    padding: 60px 60px 30px 60px;
    width: calc(100vw - 120px);
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none !important;

  }

  @media screen and (max-width: $screen-xs) {
    padding: 30px 20px 20px 20px;
    width: calc(100vw - 40px);
  }
}

%headered-wizard-paper {
  @extend %wizard-container;
  max-width: 928px;
}